var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "header flex-align-center flex-just-between" },
    [
      _c(
        "div",
        { staticClass: "flex-align-center" },
        [
          _c("a-button", {
            attrs: { type: "iconBtn", icon: "left" },
            on: {
              click: function ($event) {
                return _vm.$emit("back")
              },
            },
          }),
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _vm._t("left"),
        ],
        2
      ),
      _c("div", { staticClass: "abso-center" }, [_vm._t("center")], 2),
      _c("div", [_vm._t("right")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }