<template>
  <header class="header flex-align-center flex-just-between">
    <div class="flex-align-center">
      <a-button type="iconBtn" icon="left" @click="$emit('back')" />
      <span>{{ title }}</span>
      <!-- 左边插槽 -->
      <slot name="left" />
    </div>
    <div class="abso-center">
      <!-- 中间插槽 -->
      <slot name="center" />
    </div>
    <div>
      <!-- 右边插槽 -->
      <slot name="right" />
    </div>
  </header>
</template>

<script>

export default {
  components: {
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
    .header{
      // height:90px;
      width:100%;
      margin-bottom: 26px;
      font-weight: bold;
      position: relative;
      color: @sc-grey-100;
      .font-size(16px);
      .ant-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        height:20px;
        width:20px;
        margin-right:6px;
        padding: 0 !important;
      }
    }
</style>
